// stripe key in prod
var stripePublicKey = '<to-be-replaced>';
function resolveGrecaptchaUndefined(recaptchaCallback){
    try{
        recaptchaCallback()
    }catch(err){
        console.warn(err);
    }
}
function validateEmail(email) {
    var emailReg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    return email.length > 0 && emailReg.test(email);
}

function switchToLoginFromSignupClick() {
    $('#signupModal').modal('hide');
    $('#loginModal').modal('show');
}

function switchToSignupFromLoginClick() {
    $('#loginModal').modal('hide');
    $('#signupModal').modal('show');
}

function switchToSignupFromLoginRequestClick() {
    $('#loginRequestModal').modal('hide');
    $('#signupModal').modal('show');
}

function loginButtonClick() {
    var loginEmail = $('#loginEmail').val();
    var loginPassword = $('#loginPassword').val();
    var loginStatusText = $('#loginStatusText');
    var key = '';
    resolveGrecaptchaUndefined(function(){
        key = grecaptcha.getResponse();
    })
    loginStatusText.val("");
    if (!validateEmail(loginEmail)) {
        loginStatusText.html("Email is empty or invalid");
        return false;
    } else if (loginPassword.length < 1) {
        loginStatusText.html("Password is empty");
        return false;
    } else if (!key || key.length<1) {
        var isDev = checkIsDev();
        if(isDev){
            console.warn('Captcha can not be loaded!');  
        }else{
            loginStatusText.html("Please complete CAPTCHA verification.");
            return false;
        }
    }
    var loginData = {
        "email": loginEmail,
        "password": loginPassword,
        "key": key
    };
    $.ajax({
        type: 'POST',
        url: '/postLogin',
        data: loginData,
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                window.open('/afterlogin', '_self', false);
            } else {
                loginStatusText.html(data.errorMsg);
                resolveGrecaptchaUndefined(function(){
                    grecaptcha.reset();
                })
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            loginStatusText.html(errorMsg);
            resolveGrecaptchaUndefined(function(){
                grecaptcha.reset();
            })
        },
        processData: true
    });
    return false;
}

function loginRequestButtonClick() {
    var loginEmail = $('#loginRequestEmail').val();
    var loginPassword = $('#loginRequestPassword').val();
    var loginStatusText = $('#loginRequestStatusText');
    loginStatusText.val("");
    if (!validateEmail(loginEmail)) {
        loginStatusText.html("Email is empty or invalid");
        return false;
    } else if (loginPassword.length < 1) {
        loginStatusText.html("Password is empty");
        return false;
    }

    var loginData = {
        "email": loginEmail,
        "password": loginPassword
    };
    $.ajax({
        type: 'POST',
        url: '/postLogin',
        data: loginData,
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                window.open('/afterlogin', '_self', false);
            } else {
                loginStatusText.html(data.errorMsg);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            loginStatusText.html(errorMsg);
        },
        processData: true
    });
    return false;
}

function signupButtonClick() {
    var signupEmail = $('#signupEmail').val().trim().toLowerCase();
    var signupName = $('#signupName').val().trim();
    var signupPassword = $('#signupPassword').val().trim();
    var signupPasswordConfirm = $('#signupPasswordConfirm').val().trim();
    var signupReferralCode = '';
    var signupStatusText = $('#signupStatusText');
    signupStatusText.val("");
    console.log('signupButtonClick',signupReferralCode);
    if (!validateEmail(signupEmail)) {
        signupStatusText.html("Email is empty or invalid");
        return false;
    } else if (signupName.length < 2) {
        signupStatusText.html("Name is too short.Minimus 2 characters.");
        return false;
    } else if (signupPassword.length < 6) {
        signupStatusText.html("Password is too short.Minimus 6 characters.");
        return false;
    } else if (signupPassword.length > 100) {
        signupStatusText.html("Password is too long.Maxium 99 characters.");
        return false;
    } else if (signupPassword != signupPasswordConfirm) {
        signupStatusText.html("Passwords don't match");
        return false;
    }

    var signupData = {
        "email": signupEmail,
        "password": signupPassword,
        "name": signupName,
        "referralCode": signupReferralCode
    };
    console.log(signupData);
    $('#signup_shadowbox').show();    
    $.ajax({
        type: 'POST',
        url: '/postSignup',
        data: signupData,
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                window.open('/afterSignup?method=email', '_self', false);
            } else {
                signupStatusText.html(data.errorMsg);
            }
            $('#signup_shadowbox').hide();
        },
        error: function(jqXHR, textStatus, errorThrown) {
            $('#signup_shadowbox').hide();         
            var errorMsg = jqXHR.responseText;
            signupStatusText.html(errorMsg);
        },
        processData: true
    });
    return false;
}

function subscribeEmail() {
    var email = $('#inputSubscribeEmail').val();
    if (!email) {
        return alert('Please enter your email!')
    }
    $.ajax({
        type:'POST',
        url: '/postSubscribeWithEmail',
        data: {email: email},
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                alert('Sign up for Apollo Box Successfully!')
            } else {
                alert('Error! Please contact our customer support!')
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            console.error(errorMsg)
        },
        processData: true
    });
    return false;
}

//Account interest page
function postAccountInterestButtonClick() {
    console.error("clicked");
    $.ajax({
        type: 'POST',
        url: '/post-account-interest',
        data: $('#myinterestForm').serialize(),
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                window.open('/account-home', '_self', false);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            console.error(errorMsg);
        },
        processData: false
    });
    return false;
}

//profile setup questionaire page
function questionaireSignupButtonClick() {
    //hide all error marks
    $('#profileSetupForm').find('input').each(function(index, element) {
        markErrorForInput($(element), false);
    });
    $('#questionaireStatusPlaceHolder').html('');
    $('#questionaireStatusPlaceHolder').addClass('hidden');

    if ($('#profileSetupForm').find('input[name="firstName"]').val().length === 0) {
        markErrorForInput($('#profileSetupForm').find('input[name="firstName"]'), true);
        $('#questionaireStatusPlaceHolder').html('The shipping address is incomplete.');
        $('#questionaireStatusPlaceHolder').removeClass('hidden');
        return;
    }
    if ($('#profileSetupForm').find('input[name="lastName"]').val().length === 0) {
        markErrorForInput($('#profileSetupForm').find('input[name="lastName"]'), true);
        $('#questionaireStatusPlaceHolder').html('The shipping address is incomplete.');
        $('#questionaireStatusPlaceHolder').removeClass('hidden');
        return;
    }
    if ($('#profileSetupForm').find('input[name="street1"]').val().length === 0) {
        markErrorForInput($('#profileSetupForm').find('input[name="street1"]'), true);
        $('#questionaireStatusPlaceHolder').html('The shipping address is incomplete.');
        $('#questionaireStatusPlaceHolder').removeClass('hidden');
        return;
    }
    if ($('#profileSetupForm').find('input[name="city"]').val().length === 0) {
        markErrorForInput($('#profileSetupForm').find('input[name="city"]'), true);
        $('#questionaireStatusPlaceHolder').html('The shipping address is incomplete.');
        $('#questionaireStatusPlaceHolder').removeClass('hidden');
        return;
    }
    if ($('#profileSetupForm').find('input[name="zip"]').val().length === 0) {
        markErrorForInput($('#profileSetupForm').find('input[name="zip"]'), true);
        $('#questionaireStatusPlaceHolder').html('The shipping address is incomplete.');
        $('#questionaireStatusPlaceHolder').removeClass('hidden');
        return;
    }


    console.log($('#profileSetupForm').find('input[name="fun"][checked="checked"]'));

    var profileData = $('#profileSetupForm').serialize();
    console.log(profileData);
    $.ajax({
        type: 'POST',
        url: '/postProfileSetup',
        data: profileData,
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                window.open('/', '_self', false);
            } else {
                console.log(data.error);
                alert(data.error);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            console.log(errorMsg);
            alert(errorMsg);
        },
        processData: true
    }); //end of ajax
}

//account-home change-password page
function saveAccountHomeChangePassword() {
    var pw = $('#newpassword').val();
    var pwConfirm = $('#newpasswordConfirm').val();
    if (pw.length < 6) {
        alert("Password must be more than 6 characters.");
    } else if (pw !== pwConfirm) {
        alert("Paswords doesn't match.");
    } else {
        $.ajax({
            type: 'POST',
            url: '/account-home/postChangePassword',
            data: $('#changepasswordForm').serialize(),
            success: function(data, textStatus, jqXHR) {
                if (data.success) {
                    window.open('/account-home', '_self', false);
                } else {
                    console.log(data.error);
                    alert(data.error);
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                var errorMsg = jqXHR.responseText;
                console.log(errorMsg);
                alert(errorMsg);
            },
            processData: false
        }); //end of ajax
    }

}

//account-home update-shipping page
function updateAccountShipping() {
    var firstName = $('#UpdateShippingFirst').val();
    var lastName = $('#UpdateShippingLast').val();
    var phone = $('#UpdateShippingTelephone').val();
    var street1 = $('#UpdateShippingAddress1').val();
    var street2 = $('#UpdateShippingAddress2').val();
    var city = $('#UpdateShippingCity').val();
    var state = $('#UpdateState').val();
    var zip = $('#UpdateZip').val();
    var country = $('#UpdateCountry').val();

    if (firstName.length == 0 || lastName.length == 0 || phone.length == 0 || street1.length == 0 ||
        city.length == 0 || state.length == 0 || zip.length == 0 || country.length == 0) {
        alert("Please complete the form before saving.");
    } else {
        $.ajax({
            type: 'POST',
            url: '/account-home/post-update-shipping',
            data: {
                'firstName': firstName,
                'lastName': lastName,
                'phone': phone,
                'street1': street1,
                'street2': street2,
                'city': city,
                'state': state,
                'zip': zip,
                'country': country
            },
            success: function(data, textStatus, jqXHR) {
                if (data.success) {
                    window.open('/account-home', '_self', false);
                } else {
                    console.log(data.error);
                    alert(data.error);
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                var errorMsg = jqXHR.responseText;
                console.log(errorMsg);
                alert(errorMsg);
            },
            processData: true
        }); //end of ajax
    }
}

function editBillingStripeHandler(status, response) {
    if (response.error) {
        var stripeErrorMsg = response.error.message;
        $('#editBilling_shadowbox').hide();
        alert(stripeErrorMsg);
        return;
    }

    var cardToken = response.id;
    var cardLastFourNumber = response.card.last4;
    var cardType = response.card.brand;

    var billingData = {
        "FirstName": $("input[name='billingFirstName']").val(),
        "LastName": $("input[name='billingLastName']").val(),
        "Street1": $("input[name='billingStreet1']").val(),
        "Street2": $("input[name='billingStreet2']").val(),
        "City": $("input[name='billingCity']").val(),
        "State": $("select[name='billingState']").val(),
        "Zip": $("input[name='billingZip']").val(),
        "Country": $("select[name='billingCountry']").val(),
        "cardToken": cardToken,
        "cardLastFourNumber": cardLastFourNumber,
        "cardType": cardType
    };

    $.ajax({
        type: 'POST',
        url: '/account-billing/post-edit-billing',
        data: billingData,
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                $('#editBilling_shadowbox').hide();
                window.open('/account-home', '_self', false);
            } else {
                var editBillingMsg = data.message;
                $('#editBilling_shadowbox').hide();
                alert(editBillingMsg);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            $('#checkout_shadowbox').hide();
            alert(errorMsg);
        },
        processData: true
    });

}

// Change the status of unsubscription.
// topic - name of topic to subscribe/unsubscribe.
// to_subscribe - boolean, to subscribe or not.
function changeSubscriptionBtnClick(topic, to_subscribe) {
    $.ajax({
        type: 'POST',
        url: '/account-subscription/post-edit-subscription',
        data: {
            'topic': topic,
            'to_subscribe': to_subscribe,
        },
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                location.reload();
            } else {
                var errorMsg = data.message;
                alert(errorMsg);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            alert(errorMsg);
        },
        processData: true
    });
}

//request subscription cancellation
function cancelSubscriptionBtnClick() {
    $.ajax({
        type: 'POST',
        url: '/account-subscription/post-edit-subscription',
        data: {
            'changeKey': 'cancelled',
            'changeValue': true,
            'changeValueType': 'boolean'
        },
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                location.reload();
            } else {
                var errorMsg = data.message;
                alert(errorMsg);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            alert(errorMsg);
        },
        processData: true
    });
}

//request subscription resume
function resumeSubscriptionBtnClick() {
    $.ajax({
        type: 'POST',
        url: '/account-subscription/post-edit-subscription',
        data: {
            'changeKey': '',
            'changeValue': false,
            'changeValueType': 'boolean'
        },
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                location.reload();
            } else {
                var errorMsg = data.message;
                alert(errorMsg);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            alert(errorMsg);
        },
        processData: true
    });
}

//header search
function topSearchSubmit(){
    var formData = new FormData();
    var searchQueryText = $("input[id='product-query-input-desktop']").val();
    formData.append('search_query',searchQueryText);
    var searchFiles = $("input[id='search_image_file']").prop('files');
    if (searchFiles && searchFiles.length>=1){
        formData.append('search_image_file',searchFiles[0]);
        $('#uploadingImageModal').modal('show');
        ga('send', 'event', 'UX', 'search', 'image');
        //GA4
        gtag('event', 'search', {
            'search_term': "image"
        });
    }else{
        ga('send', 'event', 'UX', 'search', searchQueryText);
        //GA4
        gtag('event', 'search', {
            'search_term': searchQueryText
        });
    }
    $.ajax({
        type: 'POST',
        url: '/postSearchQueryAndFile',
        data: formData,
        contentType:false,
        enctype: 'multipart/form-data',
        success: function(data, textStatus, jqXHR) {
            if (data.success && data.nextUrl) {
                window.location.href = data.nextUrl; 
            } else {
                $('#uploadingImageModal').modal('hide');
                console.log(data.error);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            $('#uploadingImageModal').modal('hide');
            var errorMsg = jqXHR.responseText;
            console.log(errorMsg);
        },
        processData: false
    }); //end of ajax
}

//contact us page
function contactUsBtnClick() {
    console.log('contactUsBtnClick');
    var key = '';
    resolveGrecaptchaUndefined(function(){
        key = grecaptcha.getResponse();
    });
    console.log('grecaptcha key is '+key);
    if (!key || key.length < 10){
        if(!key){
            console.log('Captcha can not be loaded!');
            return;
        }else{
            $('#contactUsButtonStatusPlaceHolder').html('Please check the I\'m not a robot box above.');
            $('#contactUsButtonStatusPlaceHolder').addClass('card--warning');
            $('#contactUsButtonStatusPlaceHolder').removeClass('card--success');
            $('#contactUsButtonStatusPlaceHolder').removeClass('hidden');
            return;
        }
        
    } 
    var email = $('#ContactusEmail').val();
    if (!validateEmail(email)) {
        $('#contactUsButtonStatusPlaceHolder').html('Your email is invalid.');
        $('#contactUsButtonStatusPlaceHolder').addClass('card--warning');
        $('#contactUsButtonStatusPlaceHolder').removeClass('card--success');
        $('#contactUsButtonStatusPlaceHolder').removeClass('hidden');
        return;
    }
    var title = $('#ContactusSubject').val();
    if (!title || title.length < 3) {
        $('#contactUsButtonStatusPlaceHolder').html('Your subject is too short.');
        $('#contactUsButtonStatusPlaceHolder').addClass('card--warning');
        $('#contactUsButtonStatusPlaceHolder').removeClass('card--success');
        $('#contactUsButtonStatusPlaceHolder').removeClass('hidden');
        return;
    }
    var message = $('#ContactusMessage').val();
    if (!message || message.length < 5) {
        $('#contactUsButtonStatusPlaceHolder').html('Your message is too short.');
        $('#contactUsButtonStatusPlaceHolder').addClass('card--warning');
        $('#contactUsButtonStatusPlaceHolder').removeClass('card--success');
        $('#contactUsButtonStatusPlaceHolder').removeClass('hidden');
        return;
    }
    $('#contactUsButtonStatusPlaceHolder').addClass('hidden');

    var formData = $('#ContactusForm').serialize()+'&key='+key;
    $.ajax({
        type: 'POST',
        url: '/postContactusForm',
        data: formData,
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                $('#contactUsButtonStatusPlaceHolder').html('Your message has been sent.');
                $('#contactUsButtonStatusPlaceHolder').removeClass('card--warning');
                $('#contactUsButtonStatusPlaceHolder').addClass('card--success');
                $('#contactUsButtonStatusPlaceHolder').removeClass('hidden');
                //clear form
                $('#ContactusEmail').val("");
                $('#ContactusSubject').val("");
                $('#ContactusMessage').val("");
            } else {
                console.log(data.error);
                alert(data.error);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            console.log(errorMsg);
            alert(errorMsg);
        },
        processData: false
    }); //end of ajax
}


//cart page
function changeCardInCart(cb){
    var isChecked = cb.checked;
    console.log(cb.name);
    if (!isChecked){
        return false;
    }
    if (cb.name === 'addMCard'){
        $.ajax({
            type: 'POST',
            url: '/postAddToCart',
            data: {
                'sku':'sku1538',
                'title':'Magic Father\'s Day Card',
                'productImage':'https://d7909660933f5e92ab69-a09041a37f05a6f5777b6af5e3e016a3.ssl.cf2.rackcdn.com/images/sku1538_dad_card/Array5.jpg',
                'price':199,
                'is_preorder':false,
                'processingTime':2
            },
            success: function(data, textStatus, jqXHR) {
                if (data.success) {
                    window.open('/cart', '_self', false);
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                var errorMsg = jqXHR.responseText;
                console.error(errorMsg);
            },
            processData: true
        });
    }else if (cb.name === 'addBCard'){
        $.ajax({
            type: 'POST',
            url: '/postAddToCart',
            data: {
                'sku':'sku1232',
                'title':'Magic Birthday Card',
                'productImage':'https://d7909660933f5e92ab69-a09041a37f05a6f5777b6af5e3e016a3.ssl.cf2.rackcdn.com/images/sku1232-magic-birthday/Array_2.jpg',
                'price':199,
                'is_preorder':false,
                'processingTime':2
            },
            success: function(data, textStatus, jqXHR) {
                if (data.success) {
                    window.open('/cart', '_self', false);
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                var errorMsg = jqXHR.responseText;
                console.error(errorMsg);
            },
            processData: true
        });
    }else{

    }
    return false;
}


// Use sku and optionSet to identify the updated the count of product.
function changeCartCount(sku, productVariantSku, count, customizeNote) {
    $.ajax({
        type: 'POST',
        url: '/postUpdateCart',
        data: {
            "sku": sku,
            "productVariantSku":productVariantSku,
            "count": count,
            "customizeNote":customizeNote
        },
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                window.open('/cart', '_self', false);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            console.error(errorMsg);
        },
        processData: true
    });
    return false;
}

function deleteCart(sku, productVariantSku, title, customizeNote) {
    bootbox.confirm({
        size: 'medium',
        className: 'deleteCartModal',	
        message: 'Are you sure to remove " ' + title + ' " ?',
        callback: function(result) {
            deleteCartResult(result, sku, productVariantSku, customizeNote);
        }
    });
}

// Use sku and optionSet to identify the product to be removed.
function deleteCartResult(confirmed, sku, productVariantSku, customizeNote) {
    console.log('deleteCartResult', confirmed, sku, productVariantSku, customizeNote);
    if (!confirmed) {
        return false;
    }
    $.ajax({
        type: 'POST',
        url: '/postUpdateCart',
        data: {
            "sku": sku,
            "productVariantSku":productVariantSku,
            "count": 0,
            "customizeNote":customizeNote
        },
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                window.open('/cart', '_self', false);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            console.error(errorMsg);
        },
        processData: true
    });
    return false;
}

function cartApplyCoupon() {
    var couponCode = $('#cartCoupon').val() || '';
    $.ajax({
        type: 'POST',
        url: '/postUpdateCart',
        data: {
            "sku": 'couponCode',
            "options": couponCode.toUpperCase(),
            "count": 0
        },
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                window.open('/cart', '_self', false);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            console.error(errorMsg);
        },
        processData: true
    });
    return false;
}

function convertCountryCode(countryValue){
    if (!countryValue){
        console.error('convertCountryCode empty value');
        return 'US';
    }

    if (countryValue.toLowerCase()=='united states'||countryValue.toLowerCase()=='us'){
        return 'US'
    }
    if (countryValue.toLowerCase()=='canada'||countryValue.toLowerCase()=='ca'){
        return 'CA'
    }
    if (countryValue.toLowerCase()=='australia'||countryValue.toLowerCase()=='au'){
        return 'AU'
    }
    if (countryValue.toLowerCase()=='france'||countryValue.toLowerCase()=='fr'){
        return 'FR'
    }
    if (countryValue.toLowerCase()=='germany'||countryValue.toLowerCase()=='de'){
        return 'DE'
    }
    if (countryValue.toLowerCase()=='italy'||countryValue.toLowerCase()=='IT'){
        return 'IT'
    }
    if (countryValue.toLowerCase()=='mexico'||countryValue.toLowerCase()=='mx'){
        return 'MX'
    }
    if (countryValue.toLowerCase()=='malaysia'||countryValue.toLowerCase()=='my'){
        return 'MY'
    }
    if (countryValue.toLowerCase()=='new zealand'||countryValue.toLowerCase()=='nz'){
        return 'NZ'
    }
    if (countryValue.toLowerCase()=='philippines'||countryValue.toLowerCase()=='ph'){
        return 'PH'
    }
    if (countryValue.toLowerCase()=='spain'||countryValue.toLowerCase()=='es'){
        return 'ES'
    }
    if (countryValue.toLowerCase()=='singapore'||countryValue.toLowerCase()=='sg'){
        return 'SG'
    }
    if (countryValue.toLowerCase()=='turkey'||countryValue.toLowerCase()=='tr'){
        return 'TR'
    }
    if (countryValue.toLowerCase()=='great britain'||countryValue.toLowerCase()=='united kingdom' || countryValue.toLowerCase()=='gb'){
        return 'GB'
    }
    if (countryValue.toLowerCase()=='saudi arabia'||countryValue.toLowerCase()=='sa'){
        return 'SA'
    }
    console.error('convertCountryCode unknown value '+countryValue);
    return countryValue;
}

function checkoutFromCart() {
    window.location.href = "/checkout";
}

function checkoutFail(method, errorDetails){
    try{
        $.ajax({
            type:'POST',
            url:'/postCheckoutFail',
            data:{method:method,errorDetails:errorDetails},
            success:function(data, textStatus, jqXHR){
                console.log('checkoutFail success');
            },
            error:function(jqXHR, textStatus, errorThrown){
                console.log('checkoutFail ajax error',jqXHR.responseText);
            },
            processData:true
        });
    }catch(err){
        console.log('checkoutFail',err);
    }
}

function checkoutPlaceOrder() {
    // console.log('checkoutPlaceOrder',stripePublicKey)
    //hide all error marks
    $('input[type="text"]').each(function(index, element) {
        markErrorForInput($(element), false);
    });
    $('input[type="number"]').each(function(index, element) {
        markErrorForInput($(element), false);
    });    
    $('input[type="tel"]').each(function(index, element) {
        markErrorForInput($(element), false);
    });
    $('input[type="email"]').each(function(index, element) {
        markErrorForInput($(element), false);
    });
    $('#checkoutButtonStatusPlaceHolder').addClass('hidden');
    $('#checkoutButtonStatusPlaceHolderSuccess').addClass('hidden');

    // var isTermsAgreed = $('#agreeToTerms').is(':checked');
    // if (!isTermsAgreed){
    //     $('#checkoutButtonStatusPlaceHolder').html('You must agree to our terms.');
    //     $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
    //     return;
    // }

    var skipCreditCard = false;
    if ($('#totalChargeSummary').html()==='$0.00'){
        skipCreditCard = true;
    }
    var useSezzle = false;
    if ($("input[name='payment']:checked").val()=='payment-sezzle'){
        useSezzle = true;
    }

    if (checkShippingAddressError()) {
        $('#checkoutButtonStatusPlaceHolder').html('Please complete your shipping address.');
        $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
        return;
    }

    if (checkBillingAddressError()) {
        $('#checkoutButtonStatusPlaceHolder').html('Please complete your billing address.');
        $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
        return;
    }

    if ($("#billingSameAddress").length === 0) {
        //use paypal
        checkoutPlaceOrderPayPalEC();
        return;
    }

    var email = $("input[name='email']").val().replace(/[\r\n]/g, ' ');
    var phone = $("input[name='shippingPhone']").val().replace(/[\r\n]/g, ' ')||'';
    var firstName = $("input[name='shippingFirstName']").val().replace(/[\r\n]/g, ' ');
    var lastName = $("input[name='shippingLastName']").val().replace(/[\r\n]/g, ' ');

    var cardAddress1 = $("input[name='billingStreet1']").val().replace(/[\r\n]/g, ' ');
    var cardAddress2 = $("input[name='billingStreet2']").val().replace(/[\r\n]/g, ' ');
    var cardAddressCity = $("input[name='billingCity']").val().replace(/[\r\n]/g, ' ');
    var cardAddressState = $("input[name='billingState']").val().replace(/[\r\n]/g, ' ');
    var cardAddressZip = $("input[name='billingZip']").val().replace(/[\r\n]/g, ' ');
    var cardAddressCountry = $("select[name='billingCountry']").val().replace(/[\r\n]/g, ' ');
    if ($('#billingSameAddress').is(':checked')) {
        firstName = $("input[name='shippingFirstName']").val().replace(/[\r\n]/g, ' ');
        lastName = $("input[name='shippingLastName']").val().replace(/[\r\n]/g, ' ');
        cardAddress1 = $("input[name='shippingStreet1']").val().replace(/[\r\n]/g, ' ');
        cardAddress2 = $("input[name='shippingStreet2']").val().replace(/[\r\n]/g, ' ');
        cardAddressCity = $("input[name='shippingCity']").val().replace(/[\r\n]/g, ' ');
        cardAddressState = $("input[name='shippingState']").val().replace(/[\r\n]/g, ' ');
        cardAddressZip = $("input[name='shippingZip']").val().replace(/[\r\n]/g, ' ');
        cardAddressCountry = $("select[name='shippingCountry']").val().replace(/[\r\n]/g, ' ');
    }

    console.log(`[checkout]: cardAddressCountry: ${cardAddressCountry} ${convertCountryCode(cardAddressCountry)}`);

    //show animation
    $('#checkout_shadowbox').show();

    console.log('[checkout]: started()');

    if (useSezzle){
        checkoutPlaceOrderSezzle();
    }else if (skipCreditCard){
        checkoutPlaceOrderStripePaymentMethodHandler(stripePublicKey, 
            {'paymentMethod':{'id':'zero_charge_payment_method_id'}
        });
    }else if (isUseSquare =='true'){
        console.log('createToken for square',isUseSquare);
        var billingContact={
            addressLines: [cardAddress1],
            city: cardAddressCity,
            countryCode: convertCountryCode(cardAddressCountry),
            email: email,
            familyName: lastName,
            givenName: firstName,
            postalCode: cardAddressZip,
            state: cardAddressState,
        }
        var totalChargeHiddenString = $("input[name=totalChargeHiddenString]").val();
        getSquareCardToken(billingContact)
        .then(function(_tokenResult){
            if (_tokenResult.status!='OK'){
                return _tokenResult;
            }else{
                return getSquareVerifyBuyer(totalChargeHiddenString, billingContact, _tokenResult.token);
            }
        })
        .then(checkoutPlaceOrderSquareHandlerManufacter());
    }else if (isUseHelcim == 'true'){
        checkoutBeginHelcim({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            city: cardAddressCity,
            postalCode: cardAddressZip,
            state: cardAddressState,
            country: cardAddressCountry,
            line1: cardAddress1,
            line2: cardAddress2,
        });
    }else if (isUseAuthorizeNet == 'true'){
        checkoutBeginAuthorizeNet({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            city: cardAddressCity,
            postalCode: cardAddressZip,
            state: cardAddressState,
            country: cardAddressCountry,
            line1: cardAddress1,
            line2: cardAddress2,
        });
    }else{
        //get credit card token
        console.log('createToken',stripePublicKey);
        stripe.createPaymentMethod({
            type:'card',
            card: card,
            billing_details:{
                name: $("input[name='cardName']").val(),
                email: email,
                phone: phone,
                address:{
                    line1: cardAddress1,
                    line2: cardAddress2,
                    city: cardAddressCity,
                    state: cardAddressState,
                    postal_code: cardAddressZip,
                    country: convertCountryCode(cardAddressCountry)
                }
            }
        }).then(function(paymentMethodResult){
            console.log('paymentMethodResult',paymentMethodResult);
            checkoutPlaceOrderStripePaymentMethodHandler(stripePublicKey, paymentMethodResult);
        })
        console.log('[checkout]: done checkoutPlaceOrder()');
    }
    // $('#checkout_shadowbox').hide();
}

function checkoutPlaceOrderStripePaymentMethodHandler(stripePublicKey, paymentMethodResult){
    if (paymentMethodResult.error) {
        // Show error in payment form
        var stripeErrorMsg = paymentMethodResult.error.message;
        $('#checkout_shadowbox').hide();
        $('#checkoutButtonStatusPlaceHolder').html('Card Error:' + stripeErrorMsg);
        $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
        checkoutFail('stripe', 'Card Error:' + stripeErrorMsg);
        return;
    } else {
        // Otherwise send paymentMethod.id to your server (see Step 4)
        var stripePaymentMethodId = paymentMethodResult.paymentMethod.id;
        var orderData = $('#checkoutShippingForm, #checkoutBillingForm, #checkoutShippingMethodForm, #checkoutNoteForm').serialize();
        var isBillingSameAsShipping = $('#billingSameAddress').is(':checked');
        orderData = orderData + '&billingSameAddress=' + isBillingSameAsShipping;
        orderData = orderData + '&stripePaymentMethodId=' + stripePaymentMethodId;
        orderData = orderData + '&stripePublicKey=' + stripePublicKey;

        $.ajax({
            type: 'POST',
            url: '/postCheckout',
            data: orderData,
            success: function(data, textStatus, jqXHR) {
                checkoutPlaceOrderStripeHandleServerResponse(stripePublicKey,data);
            },
            error: function(jqXHR, textStatus, errorThrown) {
                var errorMsg = jqXHR.responseText;
                $('#checkout_shadowbox').hide();
                $('#checkoutButtonStatusPlaceHolder').html('Error:' + errorMsg);
                $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
                checkoutFail('stripe', 'postCheckout failed:' + errorMsg);
            },
            processData: false
        });
    }
}

function checkoutPlaceOrderStripeHandleServerResponse(stripePublicKey, checkoutResponse){
    if (checkoutResponse.success) {
        $('#checkout_shadowbox').hide();
        $('#checkoutButtonStatusPlaceHolderSuccess').html('order placed');
        $('#checkoutButtonStatusPlaceHolderSuccess').removeClass('hidden');
        //go to order confirmation page
        var orderNumber = checkoutResponse.orderNumber;
        var orderId = checkoutResponse.orderId;
        var purchaseAmount = checkoutResponse.purchaseAmount;
        var productCharge = checkoutResponse.productCharge;
        var shippingCharge = checkoutResponse.shippingCharge;
        var taxCharge = checkoutResponse.taxCharge;
        checkoutSuccess(orderNumber, orderId, purchaseAmount, shippingCharge, taxCharge, productCharge);
    } else if (checkoutResponse.requires_action){
        var actionOrderId = checkoutResponse.orderId;
        stripe.handleCardAction(checkoutResponse.payment_intent_client_secret)
        .then(function(handleCardActionResult){
            console.log('handleCardActionResult',handleCardActionResult);
            if (handleCardActionResult.error) {
                // Show error from Stripe.js in payment form
                var stripeErrorMsg = handleCardActionResult.error.message;
                $('#checkout_shadowbox').hide();
                $('#checkoutButtonStatusPlaceHolder').html('Card Authentication Error:' + stripeErrorMsg);
                $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
                checkoutFail('stripe', 'Card Action Error:' + stripeErrorMsg);
                return;
            } else {
                // The card action has been handled
                // The PaymentIntent can be confirmed again on the server
                var orderData = $('#checkoutShippingForm, #checkoutBillingForm, #checkoutShippingMethodForm, #checkoutNoteForm').serialize();
                var isBillingSameAsShipping = $('#billingSameAddress').is(':checked');
                orderData = orderData + '&billingSameAddress=' + isBillingSameAsShipping;
                orderData = orderData + '&stripePaymentIntentId=' + handleCardActionResult.paymentIntent.id;
                orderData = orderData + '&stripePublicKey=' + stripePublicKey;
                orderData = orderData + '&actionOrderId=' + actionOrderId;
                $.ajax({
                    type: 'POST',
                    url: '/postCheckoutAfterAction',
                    data: orderData,
                    success: function(data, textStatus, jqXHR) {
                        checkoutPlaceOrderStripeHandleServerResponse(stripePublicKey,data);
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        var errorMsg = jqXHR.responseText;
                        $('#checkout_shadowbox').hide();
                        $('#checkoutButtonStatusPlaceHolder').html('Error:' + errorMsg);
                        $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
                        checkoutFail('stripe', 'postCheckoutAfterAction failed:' + errorMsg);
                    },
                    processData: false
                });
            }
        })
    } else {
        var checkoutMsg = checkoutResponse.message;
        $('#checkout_shadowbox').hide();
        $('#checkoutButtonStatusPlaceHolder').html(checkoutMsg);
        $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
        checkoutFail('stripe', 'postCheckout illegal result' + checkoutMsg);
    }
}


function checkoutPlaceOrderSquareHandlerManufacter(){
    var squareHandler = function(cardResult){
        console.log('[checkout]: squareHandler()');
        if (cardResult.status != 'OK') {
            var squareErrorMsg = cardResult.msg;
            $('#checkout_shadowbox').hide();
            $('#checkoutButtonStatusPlaceHolder').html('Error:' + squareErrorMsg);
            $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
            checkoutFail('square', 'card status not OK:' + squareErrorMsg);
            return;
        }
        var sq_token = cardResult.token;
        var sq_verificationToken = cardResult.verificationToken||'';
        var orderData = $('#checkoutShippingForm, #checkoutBillingForm, #checkoutShippingMethodForm, #checkoutNoteForm').serialize();
        var isBillingSameAsShipping = $('#billingSameAddress').is(':checked');
        orderData = orderData + '&billingSameAddress=' + isBillingSameAsShipping;
        orderData = orderData + '&sqToken=' + sq_token+'&sqVerificationToken='+sq_verificationToken;
        $.ajax({
            type: 'POST',
            url: '/postCheckout',
            data: orderData,
            success: function(data, textStatus, jqXHR) {
                if (data.success) {
                    $('#checkout_shadowbox').hide();
                    $('#checkoutButtonStatusPlaceHolderSuccess').html('order placed');
                    $('#checkoutButtonStatusPlaceHolderSuccess').removeClass('hidden');
                    //go to order confirmation page
                    var orderNumber = data.orderNumber;
                    var orderId = data.orderId;
                    var purchaseAmount = data.purchaseAmount;
                    var productCharge = data.productCharge;
                    var shippingCharge = data.shippingCharge;
                    var taxCharge = data.taxCharge;
                    checkoutSuccess(orderNumber, orderId, purchaseAmount, shippingCharge, taxCharge, productCharge);
                    // window.location.href='/checkoutSuccess?on='+orderNumber+'&oi='+orderId+'&pa='+purchaseAmount;
                } else {
                    var checkoutMsg = data.message;
                    $('#checkout_shadowbox').hide();
                    $('#checkoutButtonStatusPlaceHolder').html(checkoutMsg);
                    $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
                    checkoutFail('square', 'post result not success:' + checkoutMsg);
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                var errorMsg = jqXHR.responseText;
                $('#checkout_shadowbox').hide();
                $('#checkoutButtonStatusPlaceHolder').html('Error:' + errorMsg);
                $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
                checkoutFail('square', 'post failed:' + errorMsg);
            },
            processData: false
        });
    }
    return squareHandler;
}

function checkoutPlaceOrderSezzle(){
    $('#checkout_shadowbox').show();
    var orderData = $('#checkoutShippingForm, #checkoutBillingForm, #checkoutShippingMethodForm, #checkoutNoteForm').serialize();
    var isBillingSameAsShipping = $('#billingSameAddress').is(':checked');
    orderData = orderData + '&billingSameAddress=' + isBillingSameAsShipping;
    orderData = orderData + '&paymentMethod=sezzle';
    $.ajax({
        type: 'POST',
        url: '/postCheckout',
        data: orderData,
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                $('#checkoutButtonStatusPlaceHolder').html('redirecting to sezzle');
                $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
                //go to sezzle
                var sezzleCheckoutUrl = data.sezzleCheckoutUrl;
                window.location.href = sezzleCheckoutUrl;
            } else {
                var checkoutMsg = data.message;
                $('#checkout_shadowbox').hide();
                $('#checkoutButtonStatusPlaceHolder').html(checkoutMsg);
                $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
                checkoutFail('sezzle', 'post result not success:' + checkoutMsg);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            $('#checkout_shadowbox').hide();
            $('#checkoutButtonStatusPlaceHolder').html('Error:' + errorMsg);
            $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
            checkoutFail('sezzle', 'post failed:' + errorMsg);
        },
        processData: false
    });
}

function checkoutPlaceOrderPayPalEC() {
    //show animation
    $('#checkout_shadowbox').show();
    var orderData = $('#checkoutShippingForm, #checkoutShippingMethodForm, #checkoutNoteForm').serialize();
    // orderData = orderData + '&isPaypalOrder=true';

    $.ajax({
        type: 'POST',
        url: '/postCheckout',
        data: orderData,
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                $('#checkout_shadowbox').hide();
                $('#checkoutButtonStatusPlaceHolder').html('order placed');
                $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
                //go to order confirmation page
                var orderNumber = data.orderNumber;
                var orderId = data.orderId;
                var purchaseAmount = data.purchaseAmount;
                window.location.href = '/checkoutSuccess?on=' + orderNumber + '&oi=' + orderId + '&pa=' + purchaseAmount;
            } else {
                var checkoutMsg = data.message;
                $('#checkout_shadowbox').hide();
                $('#checkoutButtonStatusPlaceHolder').html(checkoutMsg);
                $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
                checkoutFail('paypal', 'post result not success:' + checkoutMsg);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            $('#checkout_shadowbox').hide();
            $('#checkoutButtonStatusPlaceHolder').html('Error:' + errorMsg);
            $('#checkoutButtonStatusPlaceHolder').removeClass('hidden');
            checkoutFail('paypal', 'post failed:' + errorMsg);
        },
        processData: false
    });
}


function checkShippingAddressError() {
    var isError = false;
    if (isEmptyInput($("input[name='email']"))) {
        isError = true;
    }
    if (isEmptyInput($("input[name='shippingFirstName']"))) {
        isError = true;
    }
    if (isEmptyInput($("input[name='shippingLastName']"))) {
        isError = true;
    }
    if (isEmptyInput($("input[name='shippingStreet1']"))) {
        isError = true;
    }
    if (isEmptyInput($("input[name='shippingCity']"))) {
        isError = true;
    }
    if (isEmptyInput($("input[name='shippingState']"))) {
        isError = true;
    }
    if (isEmptyInput($("input[name='shippingZip']"))) {
        isError = true;
    }
    var shippingPhoneInput=$("input[name='shippingPhone']");
    if (shippingPhoneInput && shippingPhoneInput.attr('xuyao')=='yes'){
        if (isEmptyInput(shippingPhoneInput)) {
            isError = true;
        }    
    }
    return isError;
}

function checkBillingAddressError() {
    if ($("#billingSameAddress").length == 0) {
        return false; //billing address form does not exist. because it's for paypal
    }
    //check the checkbox
    if ($('#billingSameAddress').is(':checked')) {
        return checkShippingAddressError();
    }
    var isError = false;
    if (isEmptyInput($("input[name='billingFirstName']"))) {
        isError = true;
    }
    if (isEmptyInput($("input[name='billingLastName']"))) {
        isError = true;
    }
    if (isEmptyInput($("input[name='billingStreet1']"))) {
        isError = true;
    }
    if (isEmptyInput($("input[name='billingCity']"))) {
        isError = true;
    }
    if (isEmptyInput($("input[name='billingState']"))) {
        isError = true;
    }
    if (isEmptyInput($("input[name='billingZip']"))) {
        isError = true;
    }
    return isError;
}


function markErrorForInput(element, isError) {
    if (isError) {
        element.parent().addClass('form-group--error');
    } else {
        element.parent().removeClass('form-group--error');
    }
}

function isBlankString(s) {
    if (s) {
        return s.trim().length === 0;
    }
    return true;
}

function isEmptyInput(inputElement) {
    if (isBlankString(inputElement.val())) {
        markErrorForInput(inputElement, true);
        return true;
    }
    return false;
}



// init.js from frontboy

function onResize() {
    var winW = $(window).width();
    var winH = $(window).height();

    //FOOTER
    $('body').css({
        'padding-bottom': ($('.footer').outerHeight())
    });

    if (winW > 767) {
        //CAR--SPLIT HEIGHT
        $('.card--split').each(function() {
            $(this).find('.card__cover').height($(this).height());
        });
    } else {
        $('.card__cover').css('height', 'auto');
    }
}

$(document).ready(function() {
    var winW = $(window).width();
    var winH = $(window).height();

    onResize();

    $(window).on('resize',function() {
        onResize();
    }).trigger('resize');

    //SVG
    //SVG svg4everybody(); //not needed

    // MENU
    $('.menu-trigger').on('click',function() {
        $('.menu').toggleClass('open');
    });

    //SIDENAV
    $('.sidenav-trigger').on('click',function() {
        $('body').addClass('sidenav-on')
        $('main').on('click',function() {
            if ($('body').hasClass('sidenav-on')) {
               $('body').removeClass('sidenav-on');
            }
        })
    });
    //SIDENAV COUNTRY SELECTION

    var getUserCountryFromBrowser = function(){
        var matchedResult = localStorage.getItem('cf')
        if(!matchedResult){
            var languageFlag = window.navigator.userLanguage || window.navigator.language;
            var targetFlags = ['US','AU','CA','FR','DE','IT','SA','ES','TR','GB','NZ','MX','MY','SG']; //TODO chagne this when when add more countries, order must match
            languageFlag.toUpperCase()
            // if languageFlag != EN or FR 
            if(languageFlag && languageFlag.split('-').length == 2){
                var countryFlag = languageFlag.split('-')[1];
                targetFlags.forEach(function(targetFlag){
                    if(targetFlag==countryFlag){
                        matchedResult = targetFlag;
                    }
                })
            }
            // default flag = US
            if(!matchedResult){
                matchedResult = 'US';
            }
            // localStorage.setItem('cf',matchedResult);
        }
        return matchedResult;
    }
    
    var countryOptions = $('.sidenav-country-option');
    var deskTopCountryOptions = $('.icon-country-option');
    $('#header3CountrySelection').on('mouseenter',function(){
        $('.countryOptions').css({
            display:'block'
        })
    })

    $('#header3CountrySelection').on('mouseleave',function(){
        $('.countryOptions').css({
            display:'none'
        })
    })

    var handleSidenavCountryTitle = function(flag){
        var titleHtml = '';
        titleHtml =  '<img class="sidenav-current-country-flag__img" id="sidenav-current-country-'+flag+'" src="https://rs.apolloboxassets.com/hosted/img/flag_'+flag+'.png"/><span data-country="'+flag+'" class="sidenav-current-country-text"> Ship To '+flag+'</span>'
        $('#sidenav-current-country-slot').html(titleHtml);
        collapseCountryMenu();
    }

    var handleNavCountryTitle = function(flag){
        var titleHtml = '';
        titleHtml = '<img class="country-flag__img current-country-flag-'+flag+'"  id="current-country-flag" data-country="'+flag+'" src="https://rs.apolloboxassets.com/hosted/img/flag_'+flag+'.png" alt="United States" />'
        $('#nav-current-country-slot').html(titleHtml);
        hideCountryMenu();
    }

    var selectCountry = function(key,countryOptions){
        var curFlag = '';
        countryOptions.each(function(idx,ele){
            if(idx!=key){
                ele.dataset.selected = 0;
            }else {
                ele.dataset.selected = 1;
                curFlag = ele.dataset.country
            }
        })
        return curFlag;
    }
    var collapseCountryMenu = function(){
        $('#sidenav-current-country').addClass('collapsed');
        $('#sidenav-current-country').attr('aria-expanded',false);
        $('#sidenav-country-options').removeClass('show');
    }
    var hideCountryMenu = function(){
        $('.countryOptions').css({
            display:'none'
        })
    }
    var IS_NOT_USER_ACTION = false;
    var IS_USER_ACTION = true;
    var handleUpdateCountry =  function(flag,isUserAction,callback){
        if(callback){
            callback(flag);
        }
        $.ajax({
            method:'POST',
            url:'/api/postUpdateUserCountryFlag',
            data:{
                countryFlag:flag,
                isUserAction:isUserAction
            },
            success:function(res){
                if(res.success){
                    if (res.updated){
                        localStorage.setItem('cf',res.countryFlag)
                        var existingURL = window.location.href.toString();
                        var newURL = updateUrlParameter(existingURL, '_country',res.countryFlag,true);//replace _country flag if existing
                        if (newURL === existingURL){
                            window.location.reload(true);
                        }else{
                            window.location.href = newURL;
                        }
                    }else{
                        if(callback){
                            callback(res.countryFlag);
                        }
                    }
                }else{
                    console.log('handleUpdateCountry error '+res.errorMsg);
                }
            },
            error:function(err){
                console.log('handleUpdateCountry error',err);
            }
        });
    }

    //init options
    var currentFlag = getUserCountryFromBrowser();
    var remoteFlag = getRemoteCountryFlag();
    var remoteFlagPriority = getRemoteFlagPriority() ;
    if(currentFlag != remoteFlag && remoteFlagPriority<=4 ){
        handleUpdateCountry(currentFlag, IS_NOT_USER_ACTION);
    }

    countryOptions.each(function(idx,ele){
        var $ele = $(ele);
        $ele.on('click',function(){
            var thisEleFlag = this.dataset.country;
            var titleCountry = $('.sidenav-current-country-text').data('country');
            if(titleCountry == thisEleFlag){
                collapseCountryMenu();
            }else{
                var countryFlag = selectCountry(idx,countryOptions,thisEleFlag);
                handleUpdateCountry(countryFlag,IS_USER_ACTION,handleSidenavCountryTitle);
            }
        });
    })
    deskTopCountryOptions.each(function(idx,ele){
        var $ele = $(ele);
        $ele.on('click',function(){
            var thisEleFlag = this.dataset.country;
            var titleCountry = $('#current-country-flag').data('country');
            if(titleCountry == thisEleFlag){
                hideCountryMenu();
            }else{
                var countryFlag = selectCountry(idx,countryOptions,thisEleFlag);
                handleUpdateCountry(countryFlag,IS_USER_ACTION,handleNavCountryTitle);
            }
            
        });
    })
});


//AUTOCOMPLETE
$(function() {
    /* top search bar fake auto complete */
    // var projects = [
    //   {
    //     value: "product.html",
    //     label: "Spotter UNIQ",
    //     icon: "images/product01.jpg"
    //   },
    //   {
    //     value: "product.html",
    //     label: "MiFA F5 Wireless Speaker",
    //     icon: "images/product02.jpg"
    //   },
    //   {
    //     value: "product.html",
    //     label: "Levitating Bluetooth Speaker II",
    //     icon: "images/product04.jpg"
    //   }
    // ];

    // $( "#product" ).autocomplete({
    //   minLength: 0,
    //   source: projects,
    //   select: function( event, ui ) {
    //     window.location.href = ui.item.value;

    //     return false;
    //   }
    // })
    // .autocomplete( "instance" )._renderItem = function( ul, item ) {
    //   return $( "<li>" )
    //     .append( "<a>" + "<img class='autocomplete__img' src='" + item.icon + "'>" + item.label + "</a>" )
    //     .appendTo( ul );
    // };
    /* end of top search bar fake auto complete */

    //INPAGE LINK
    $('.inpage').on('click',function() {
        var target = $(this).data('target');

        $('html, body').animate({
            scrollTop: $(target).offset().top
        }, 500);
    });

    //REVIEW NEW
    $("#review-new").on("shown.bs.collapse", function() {
        $('html, body').animate({
            scrollTop: ($(this).offset().top - 100)
        }, 200);
    });

    $('.review-open').on('click',function() {
        $('#review-new').collapse('show');
    });

    //store review new
    // $("#store-review-new").on("shown.bs.collapse", function() {
    //     $('html, body').animate({
    //         scrollTop: ($(this).offset().top - 100)
    //     }, 200);
    // });

    // $('.store-review-open')on('click',function() {
    //     $('#store-review-new').collapse('show');
    // });

    //INPUTS LABELS
    $(".form-group--label input").each(function() {
        if ($(this).val() != "") {
            $(this).addClass('filled');
        } else {
            $(this).removeClass('filled');
        }
    }).on('change',function() {
        if ($(this).val() != "") {
            $(this).addClass('filled');
        } else {
            $(this).removeClass('filled');
        }
    });

    //MODAL FLIP
    $('.modal--flip .flip-btn-link').on('click',function() {
        $(this).parents('.modal--flip').addClass('turn');
    });
    $('.modal--flip').on('hidden.bs.modal', function() {
        $(this).removeClass('turn');
    });

});

//OUTSIDE MENU
$(document).on('mouseup',function(e) {
    var container = $('.menu');

    if (!container.is(e.target) // if the target of the click isn't the container...
        &&
        container.has(e.target).length === 0) // ... nor a descendant of the container
    {
        container.removeClass('open');
    }
});

// end init.js


$(document).ready(function() {

    $('#signupModal').on('show.bs.modal', function(event) {
        $('body').removeClass('sidenav-on')
    });
    $('#loginModal').on('show.bs.modal', function(event) {
        $('body').removeClass('sidenav-on')
    });
    $(window).on('scroll',function(){
       if($(window).scrollTop()>350){
           $('a.back-to-top').fadeIn('slow');
       } else{
           $('a.back-to-top').fadeOut('slow');
       }
    });
    $('.back-to-top').on('click',function(event){
        event.preventDefault();
        $('html,body').animate({scrollTop:0},500);
        return false;
    });

    // jQuery for page scrolling feature - requires jQuery Easing plugin
    // $(function() {
    //     $('.local-scroll a').bind('click', function(event) {
    //         var $anchor = $(this);
    //         $('html, body').stop().animate({
    //             scrollTop: $($anchor.attr('href')).offset().top
    //         }, 1200);
    //         event.preventDefault();
    //     });
    // });

    //send login and signup dialog open events to google
    $('#loginModal').on('shown.bs.modal', function(e) {
        ga('send', 'event', 'button', 'click', 'loginModal');
        gtag('event', 'showLoginModel',{});
    });

    $('#signupModal').on('shown.bs.modal', function(e) {
        ga('send', 'event', 'button', 'click', 'signupModal');
        gtag('event', 'showSignupModel', {});
    });

});

// REVIEW, used with product-review-composite2.ejs

/*
    Post review to server. If successfull, prepend review to review container.
*/
function postReview(imageUrls) {
    var title = $('#review_title_input').val();
    if (!title || title.length <= 0) {
        bootbox.alert('Review subject cannot be empty.');
        return;
    }
    var text = $('#review_text_textarea').val();
    if (!text || text.length <= 0) {
        bootbox.alert('Review details cannot be empty.');
        return;
    }
    var images = [];
    imageUrls.filter(function(image) {
        return verify_image_filename(image);
    }).forEach(function(imageUrl) {
        images.push("images=" + encodeURIComponent(imageUrl));
    });
    var review_to_post = $("form[id='new-review-form']").serialize();
    review_to_post += ("&" + images.join("&"));
    $.ajax({
        type: 'POST',
        url: './review/new',
        data: review_to_post,
        success: function(data, textStatus, jqXHR) {
            var response = jQuery.parseJSON(jqXHR.responseText);
            if (response.new_review_html) {
                $("div[id='review-container']").prepend(response.new_review_html);
                $("div[id='review-new']").collapse('hide');

                if (response.product) {
                    if (response.product.num_reviews) {
                        $("div #product-review-header h3").text(
                            "Reviews(" + response.product.num_reviews + ")"
                        );
                    }

                    if (response.product.rating) {
                        for (var i = 1; i <= 5; i++) {
                            if (response.product.rating >= i) {
                                $("#product_rating_icon_full_" + i).attr('style', 'display:inline-block');
                                $("#product_rating_icon_empty_" + i).attr('style', 'display:none');
                            } else {
                                $("#product_rating_icon_full_" + i).attr('style', 'display:none');
                                $("#product_rating_icon_empty_" + i).attr('style', 'display:inline-block');
                            }
                        }
                    }
                }
                $('html, body').scrollTop(
                    $("div[id='review-container']").offset().top
                );
            } 
            bootbox.confirm("Thanks for posting your review. We are currently processing your review and will display it as soon as this is complete (approximately 1 - 2 days).",function(){
                $('#review-new').removeClass('in');
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                window.history.back(-1);
            });
            
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.log(jqXHR.responseText);
            var errorObject = jQuery.parseJSON(jqXHR.responseText);
            if ('user_not_login' in errorObject) {
                bootbox.confirm('Only member can write review. Go to login?', function(result) {
                    if (result) {
                        $('#loginModal').modal('show');
                    }
                });
            } else if ('fields_missing' in errorObject) {
                bootbox.alert('Post review failed! Subject, details and star rating are all required.');
            } else {
                bootbox.alert('Post review failed! Unknown error...');
            }
        },
        processData: true
    });
}

// REVIEW upload images

function show_review_updating_progress(input_elem, shown, error_message_on_dismiss) {
    if (shown) {
        // console.log('show progress');
        //disable the submit button
        $('#review-submit').prop("disabled", true);
        //show a spinner
        $(input_elem).siblings('#review-upload-image-spinner').removeClass('hidden');

    } else {
        // console.log('hide progress');
        //enable thes submit button
        $('#review-submit').prop("disabled", false);
        //hide the spinner
        $(input_elem).siblings('#review-upload-image-spinner').addClass('hidden');
        if (error_message_on_dismiss.length > 0) {
            alert(error_message_on_dismiss);
        }
    }
}
/*
    Function to carry out the actual PUT request to S3 using the signed request from the app.
*/
function upload_file(file, signed_request, url, input_elem) {
    // console.log("upload:", url);

    var xhr = new XMLHttpRequest();
    xhr.open("PUT", signed_request);
    xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.onload = function() {
        if (xhr.status === 200) {
            // Add images to preview and show delete <a>.
            show_review_updating_progress(input_elem, false, '');
            $(input_elem).siblings('img#review-upload-image-preview').attr('src', url);
            $(input_elem).siblings('button#review-upload-image-delete').removeClass('hidden');
            // Add one more controller to container.
            var container = $(input_elem).closest('#review-upload-image-container');
            add_image_upload_group(container);
        } else {
            show_review_updating_progress(input_elem, false, 'Oops, error uploading image to server. Error#1240.');
        }
    };
    xhr.onerror = function() {
        show_review_updating_progress(input_elem, false, 'Oops, error uploading image to server. Error#1243.');
    };
    xhr.send(file);
}

/* Post store review to server, if successfull, prepend review to review container.
*/
function postStoreReview() {
    var title = $('#store_review_title_input').val();
    if (!title || title.length <= 0) {
        bootbox.alert('Store Review subject cannot be empty.');
        return;
    }
    var text = $('#store_review_text_textarea').val();
    if (!text || text.length <= 0) {
        bootbox.alert('Store Review details cannot be empty.');
        return;
    }
    var images = [];
    $('img#review-upload-image-preview').filter(function() {
        return verify_image_filename(this.src);
    }).each(function() {
        images.push("images=" + encodeURIComponent(this.src));
    });

    var review_to_post = $("form[id='new-store-review-form']").serialize();
    review_to_post += ("&" + images.join("&"));
    console.log($("form[id='new-store-review-form']").serializeArray())
    $.ajax({
        type: 'POST',
        url: './review/new',
        data: review_to_post,
        success: function(data, textStatus, jqXHR) {
            var response = jQuery.parseJSON(jqXHR.responseText);
            if (response.new_review_html) {
                $("div[id='review-container']").prepend(response.new_review_html);
                $("div[id='review-new']").collapse('hide');

                if (response.product) {
                    if (response.product.num_reviews) {
                        $("div #product-review-header h3").text(
                            "Reviews(" + response.product.num_reviews + ")"
                        );
                    }

                    if (response.product.rating) {
                        for (var i = 1; i <= 5; i++) {
                            if (response.product.rating >= i) {
                                $("#product_rating_icon_full_" + i).attr('style', 'display:inline-block');
                                $("#product_rating_icon_empty_" + i).attr('style', 'display:none');
                            } else {
                                $("#product_rating_icon_full_" + i).attr('style', 'display:none');
                                $("#product_rating_icon_empty_" + i).attr('style', 'display:inline-block');
                            }
                        }
                    }
                }
                $('html, body').scrollTop(
                    $("div[id='review-container']").offset().top
                );
            } else {
                bootbox.alert("Thanks for posting your review. We are currently processing your review and will display it as soon as this is complete (approximately 1 - 2 days).");
            }
            $('#store-review-new').collapse("hide");
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.log(jqXHR.responseText);
            var errorObject = jQuery.parseJSON(jqXHR.responseText);
            if ('user_not_login' in errorObject) {
                bootbox.confirm('Only member can write review. Go to login?', function(result) {
                    if (result) {
                        $('#loginModal').modal('show');
                    }
                });
            } else if ('fields_missing' in errorObject) {
                bootbox.alert('Post review failed! Subject, details and star rating are all required.');
            } else {
                bootbox.alert('Post review failed! Unknown error...');
            }
        },
        processData: true
    });
}
/*
    Function to get the temporary signed request from the app.
    If request successful, continue to upload the file using this signed
    request.
*/
function get_signed_request(file, input_elem) {
    show_review_updating_progress(input_elem, true, '');
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "/sign_s3?file_name=" + file.name + "&file_type=" + file.type);
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                var response = JSON.parse(xhr.responseText);
                upload_file(file, response.signed_request, response.url, input_elem);
            } else {
                show_review_updating_progress(input_elem, false, 'Oops, error uploading image to server. Error#1263.');
            }
        }
    };
    xhr.send();
}
/*
   Function called when file input updated. If there is a file selected, then
   start upload procedure by asking for a signed request from the app.
*/
function init_upload(elem) {
    var files = elem.files;
    var file = files[0];
    if (file == null) {
        alert("No file selected.");
        delete_image_upload_group(elem);
        return;
    } else if (!verify_image_filename(file.name)) {
        alert("Not valid filename. Please choose image file with extension: png,jpeg,jpg,gif.");
        delete_image_upload_group(elem);
        return;
    }
    get_signed_request(file, elem);
}

function verify_image_filename(filename) {
    if (filename && filename.length) {
        var extension = filename.split('.').reverse()[0].toLowerCase();
        return $.inArray(extension, ['png', 'jpeg', 'jpg', 'gif']) >= 0;
    }
    return false;
}

/*
   Add a new image upload group to container.
*/
(function() {
    var container = $('#review-upload-image-container');
    add_image_upload_group(container);
})();

/*
    Add image upload group to the container.
    container - selected review-upload-image-container
*/
function add_image_upload_group(container) {
    // console.log('add_image_upload_group');
    // 1. container is empty.
    // OR
    // 2. a. container has less than 3 groups.
    // 2. b. the last image preview of container is set.
    var uploadIndex = 4;
    if (!container.children().length) {
        uploadIndex = 1;
    } else if (container.children().length < 3 && container.find('img:last').attr('src')) {
        uploadIndex = container.children().length + 1;
    }
    if (uploadIndex <= 3) {
        container.append('<div id="review-upload-image-group">' +
            '<input type="file" id="review-upload-image-' + uploadIndex + '" name="review-upload-image-' + uploadIndex + '" class="inputfile inputfile-2" onchange="init_upload(this)"><label for="review-upload-image-' + uploadIndex + '"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/></svg> <span>Choose a file&hellip;</span></label>' +
            '<div class="throbber hidden" id="review-upload-image-spinner" style="margin-left:45px;">upload spinner</div>' +
            '<img class="review-upload-image-preview"  id="review-upload-image-preview" />' +
            '<button class="hidden btn-warning btn-sm" id="review-upload-image-delete">remove</button> ' +
            '</div>');
        $('input#review-upload-image').attr('onchange', 'init_upload(this)');
        $('button#review-upload-image-delete').attr('onclick', 'delete_image_upload_group(this)');
    }
}

/*
    Delete image upload group including the input element.
    Add a new group if necessary.
    elem - <a> element controls image deleting.
*/
function delete_image_upload_group(elem) {
    var container = $(elem).closest('#review-upload-image-container');
    $(elem).closest('div#review-upload-image-group').remove();
    // Add image upload group if necessary.
    add_image_upload_group(container);
}

function promopt_login_after_long_visit_real(){
    if (!parseInt(getCookie_('page_long_visit'))) {
        $('#loginRequestModal').modal('show');
    } else {
        console.log('cookie has page_long_visit no need to prompt');
    }
    // console.log('hit server with', getCookie_('page_long_visit'));
    $.ajax({
        type: 'POST',
        url: '/promptUserLogin',
        data: {},
        success: function(data, textStatus, jqXHR) {
            return;
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            // loginStatusText.html(errorMsg);
        },
        processData: true
    });
    return false;
}
/*
 */
function promopt_login_after_long_visit() {
    return;//to prevent conflict with attentive
    if (!parseInt(getCookie_('page_long_visit'))) {
        $('#loginRequestModal').modal('show');
    } else {
        console.log('cookie has page_long_visit no need to prompt');
    }
    // console.log('hit server with', getCookie_('page_long_visit'));
    $.ajax({
        type: 'POST',
        url: '/promptUserLogin',
        data: {},
        success: function(data, textStatus, jqXHR) {
            return;
        },
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            // loginStatusText.html(errorMsg);
        },
        processData: true
    });
    return false;
}

/* Get value of cname from cookie.
 */
function getCookie_(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function quickAddtoCart(element) {
    var productSku = $(element)[0].dataset.type;
    window.event.cancelBubble = true;
    $.ajax ({
        type: 'POST',
        url: '/postQuickAddtoCart',
        data: {sku: productSku},
        success: function(data, textStatus, jqXHR) {
            if (data.success) {
                window.open('/cart', '_self', false)
            } else {
                alert('Error! please contact our customer support!')
            }
        }, 
        error: function(jqXHR, textStatus, errorThrown) {
            var errorMsg = jqXHR.responseText;
            console.error(errorMsg)
        },
        processData: true
    });
    return false;
}


function updateUrlParameter(uri, key, value, isReplaceOnly) {
    // remove the hash part before operating on the uri
    var i = uri.indexOf('#');
    var hash = i === -1 ? ''  : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);
    if (hash=='#') hash='';//we do not want empty hash
  
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  
    if (!value) {
      // remove key-value pair if value is empty
      uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), '');
      if (uri.slice(-1) === '?') {
        uri = uri.slice(0, -1);
      }
      // replace first occurrence of & by ? if no ? is present
      if (uri.indexOf('?') === -1) uri = uri.replace(/&/, '?');
    } else if (uri.match(re)) {
      uri = uri.replace(re, '$1' + key + "=" + value + '$2');
    } else if(!isReplaceOnly) {
      uri = uri + separator + key + "=" + value;
    }
    return uri + hash;
  }

function getRandomEventId(){
    return (new Date()).toLocaleString('en-US')+'_' + Math.random().toString(36).substr(2, 9);
}

function sendFbConvEvent(eventName, eventData, eventId){
    return false;
    // try{
    //     $.ajax({
    //         type: 'POST',
    //         url: '/api/fbConvApi/trackEvent',
    //         data: {
    //             apiVersion:1,
    //             eventSourceUrl:window.location.origin + window.location.pathname,
    //             eventName:eventName,
    //             eventData:eventData,
    //             eventId:eventId,
    //             fbp: Cookies.get('_fbp')||'',
    //             fbc: Cookies.get('_fbc')||'',
    //         },
    //         success: function(data, textStatus, jqXHR) {
    //             console.log('sendFbConvEvent success',jqXHR.responseText)
    //         },
    //         error: function(jqXHR, textStatus, errorThrown) {
    //             console.log('sendFbConvEvent error',jqXHR.responseText)
    //         },
    //         processData: true
    //     });
    // }catch(e){
    //     console.error(e);
    // }
}
