
/**
 * Begin of SubMenuSystem Block
 * Functions for setup the menu-submenu hover/click actions on touch screens with no hover support
 */
function setMouseenter(ele){
  ele.on('mouseenter',function(){
    ele.children('ul').addClass('show-menu')
  })
  ele.on('mouseleave',function(){
    ele.children('ul').removeClass('show-menu')
  })
  var childrenLi = ele.children('ul').children('li')
  if(childrenLi.length>0){
    for(var i =0;i<childrenLi.length;i++){
      setMouseenter($(childrenLi[i]));
    }
  }
}
function inactive(eleList){
  for ( var i = 0;i<eleList.length; i++){
      $(eleList[i]).children('a').removeClass('menu-item__active');
  }
}
function cancelStatus(){
  $('.show-menu').removeClass('show-menu');
  $('.menu-item__active').removeClass('.menu-item__active');
}
function setSubMenu(eleList){
  for ( var i = 0;i<eleList.length; i++){
      eleList[i].dataset.open = 0;
      var ele = $(eleList[i]);
      ele.off('keyup').on('keyup',function(e){
        var $this = $(this);
        if(e.keyCode==9){
          inactive(eleList);
          $this.children('a').addClass('menu-item__active');
          if($this.children('ul').children('li').length>0){
            setSubMenu($this.children('ul').children('li'));
          }
        }
        if(e.keyCode==13){
          if($this.children('ul').length>0&&this.dataset.open==0){
            $this.children('ul').addClass('show-menu');
            $this.attr('data-open',1)
          }else if($this.children('ul').length>0&&this.dataset.open==1){
            $this.children('ul').removeClass('show-menu');
            $this.attr('data-open',0)
          }
        }
      })
  }
  $(eleList[eleList.length-1]).on('keydown',function(e){
    this.dataset.islast = 1;
    var $this = $(this);
    if(e.keyCode==9){
      if($this.children('ul').length>0&&this.dataset.open==0){
        $this.children('a').removeClass('menu-item__active');		
        $this.parent().removeClass('show-menu');
      }else if($this.children('ul').length==0){
        $this.children('a').removeClass('menu-item__active');	
        $this.parent().removeClass('show-menu');
        if($this.parent().parent().parent().dataset){
          this.dataset.islast == 1 ?$this.parent().parent().parent().removeClass('show-menu'):null;
        }
      }
    }
  })
}
/* use this function to setup the actions */
function setupSubMenuSystemForNoHoverDevice(){
  var $menu1 = $('#shopByCategoryMenu');
  var $menu2 = $('#shopByCollectionMenu');
  setMouseenter($menu1);
  setMouseenter($menu2);
  window.addEventListener('click',function(e){
    var ev = window.event || event;
    var ePath = ev.path||(ev.composedPath && ev.composedPath());
    var isInArea = ePath.some(function(ele){
      var id = ele.id
      return id =='icon-shop-category'|| id == 'icon-shop-collection'
    })
    if(!isInArea){
      cancelStatus();
    }
  })	
  
  $menu1.on('keyup',function(e){
    console.log(`setupSubMenuSystem 1 keyup event`)
    if(e.keyCode==9){
      var $firstLevel = $(this.children[1])
      $firstLevel.addClass('show-menu');
      var sub1MenuList = $('#icon-shop-category').children('li');
      setSubMenu(sub1MenuList);
    }
  })
  $menu2.on('keyup',function(e){
    if(e.keyCode==9){
      var $firstLevel = $(this.children[1]);
      $firstLevel.addClass('show-menu');
      var sub1MenuList = $('#icon-shop-collection').children('li');
      setSubMenu(sub1MenuList);
    }
  })
}//end of setupSubMenuSystemForNoHoverDevice
/**
 * End of SubMenuSystem Block
 */


/** accessibility helper */
function makeCloseBtnFocusableForModel(){
  $('#switchToRegisterForm').on('blur',function(e){
    $('.close').trigger('focus');
  })
  $('#lastEleOfSignupModel').on('blur',function(e){
    $('.close').trigger('focus');
  })
}


$(document).ready(function() {
    //SVG svg4everybody(); //not needed
  
      // Toggle menu
      $('.search-trigger').on('click',function() {
          $('body').addClass('search-on');
          $('.search .form-control').focus();
      });
  
      $('.search__close').on('click',function() {
          $('body').removeClass('search-on');
      });

      $('.search__image').on('click',function() {
        $('#search_image_file').click();
      });
      $('#search_image_file').change(function() {
        $('#top_search_form').submit();
      });
    
      $('.sidenav-trigger').on('click',function() {
          $('body').addClass('sidenav-on');
      });
  
      $('.sidenav__close').on('click',function() {
          $('body').removeClass('sidenav-on');
      });
  
  
      //SLICK
      $('.carousel--shop').slick({
      accessibility:true,
      dots: true,
      infinite: true,
      speed: 500,
      cssEase: 'linear',
      adaptiveHeight: true,
      prevArrow:"<a href='#!' class='slick-arrow slick-arrow--prev' role='none' tabindex='-1' name='previous product image button for shop' aria-label='previous product image button for shop' style='color:#1cb4d1;background-color:rgba(255,255,255,0.0)'><svg role='img' class='icon icon-angle-left'><use xlink:href='/icons/icons.svg#icon-angle-left'></use></svg></a>",
      nextArrow:"<a href='#!' class='slick-arrow slick-arrow--next' role='none' tabindex='-1' name='next product image button for shop' aria-label='next product image button for shop' style='color:#1cb4d1;background-color:rgba(255,255,255,0.0)'><svg role='img' class='icon icon-angle-right'><use xlink:href='/icons/icons.svg#icon-angle-right'></use></svg></a>",
      responsive: [
        {
          breakpoint: 576,
          settings: {
            dots: false,
            arrows: false
          }
        }
      ]
    }); 
  
    $('.carousel--product').slick({
      accessibility:true,
      infinite: true,
      speed: 300,
      arrows: true,
      prevArrow:"<a href='#!' class='slick-arrow slick-arrow--prev' role='none' tabindex='-1' name='previous product image button for product' aria-label='previous product image button for product'><svg role='img' class='icon icon-angle-left'><use xlink:href='icons/icons.svg#icon-angle-left'></use></svg></a>",
      nextArrow:"<a href='#!' class='slick-arrow slick-arrow--next' role='none' tabindex='-1' name='next product image button for product' aria-label='next product image button for product'><svg role='img' class='icon icon-angle-right'><use xlink:href='icons/icons.svg#icon-angle-right'></use></svg></a>",
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    }); 
  
    $('.carousel--xl').slick({
      accessibility:true,
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      arrows: false,
      cssEase: 'linear',
      asNavFor: '.carousel--md'
    });
  
    $('.carousel--md').slick({
      accessibility:true,
      infinite: false,
      speed: 400,
      arrows: true,
      prevArrow:"<a href='#!' class='slick-arrow slick-arrow--prev' role='none' tabindex='-1' name='previous product image button for product image review' aria-label='previous product image button for product image review'><svg role='img' class='icon icon-angle-left'><use xlink:href='/icons/icons.svg#icon-angle-left-new'></use></svg></a>",
      nextArrow:"<a href='#!' class='slick-arrow slick-arrow--next' role='none' tabindex='-1' name='next product image button for product image review' aria-label='next product image button for product image review'><svg role='img' class='icon icon-angle-right'><use xlink:href='/icons/icons.svg#icon-angle-right-new'></use></svg></a>",
      slidesToShow: 4,
      slidesToScroll: 1,
      focusOnSelect: true,
      asNavFor: '.carousel--xl',
      responsive: [
        {
          breakpoint: 750,
          settings: {
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1
          }
        }
      ]
    });
  
    /*call this after the slick carousel has been setup so it does not load all images from the carousel
    * because before the slick is inited, all images are considered visiable
    */
    lazySizes.init(); 
    //add simple support for lazy load background images:
		document.addEventListener('lazybeforeunveil', function(e){
			var bg = e.target.getAttribute('data-bg');
			if(bg){
				e.target.style.backgroundImage = 'url(' + bg + ')';
			}
		},{passive: true});

    //Select
    $('.select2').select2({
        minimumResultsForSearch: Infinity
    });
  
  
    //Quantity
    $('<div class="btn btn--secondary qty__down">-</div>').insertBefore('.qty input');
    $('<div class="btn btn--secondary qty__up">+</div>').insertAfter('.qty input');
    $('.qty').each(function() {
      var spinner = $(this),
        input = spinner.find('input[type="number"]'),
        btnUp = spinner.find('.qty__up'),
        btnDown = spinner.find('.qty__down'),
        min = input.attr('min'),
        max = input.attr('max');
  
      btnUp.on('click',function() {
        var oldValue = parseFloat(input.val());
        if (oldValue >= max) {
          var newVal = oldValue;
        } else {
          var newVal = oldValue + 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
      });
  
      btnDown.on('click',function() {
        var oldValue = parseFloat(input.val());
        if (oldValue <= min) {
          var newVal = oldValue;
        } else {
          var newVal = oldValue - 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
      });
  
    });
  

    setupSubMenuSystemForNoHoverDevice();
    makeCloseBtnFocusableForModel();

}); //end of the ready function